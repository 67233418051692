import React, {
  useState,
  useEffect
} from 'react'
import {
  useFormik
} from 'formik'
import * as Yup from 'yup'
import {
  Alert,
  DataStatus,
  ActionButton,
} from '../../../../../components'
import { Modal } from 'react-bootstrap'
import {
  RegistrasiKaryawanApi
} from '../../../../../api'
import { 
  IoTextOutline, IoAddOutline, IoPencilOutline, 
  IoTrashOutline, IoCheckmarkOutline, IoCloseOutline,
} from 'react-icons/io5'

const FormDataKeluarga = ({idKaryawan}) => {
  const [isFormHeader, setIsFormHeader] = useState(false)
  const [loadingTambah, setLoadingTambah] = useState(false)
  const [loading, setLoading] = useState(false)
  const [processedData, setProcessedData] = useState({})
  const [editedData, setEditedData] = useState({})
  const [dataKeluarga, setDataKeluarga] = useState([])
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'danger'
  })

  useEffect(() => {
    getDataKeluarga()
  }, [])

  const getDataKeluarga = () => {
    setLoading(true)

    RegistrasiKaryawanApi.getKeluarga(idKaryawan)
    .then(res => setDataKeluarga(res.data.data_keluarga))
    .finally(() => setLoading(false))
  }

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle'
  }
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: ''
  })

  const formInitialValues = {
    id_anggota_keluarga: "",
    jenis_anggota_keluarga: "",
    nama_anggota_keluarga: "",
    tgl_lahir: "",
    keterangan: "",
  }

  const formSubmitHandler = values => {
    setLoadingTambah(true)

    RegistrasiKaryawanApi.updateDataKeluarga({
      id_karyawan: idKaryawan,
      data_keluarga: [
        {
          jenis_anggota_keluarga: values.jenis_anggota_keluarga,
          nama_anggota_keluarga: values.nama_anggota_keluarga,
          tgl_lahir: values.tgl_lahir,
          keterangan: values.keterangan
        }
      ]
    })
    .then(() => setAlertConfig({
      show: true,
      variant: 'primary',
      text: 'Data berhasil ditambah!'
    }))
    .catch(() => setAlertConfig({
      show: true,
      variant: 'danger',
      text: 'Data gagal ditambah!'
    }))
    .finally(() => {
      setLoadingTambah(false)

      getDataKeluarga()
    })
  }

  const formValidationSchema = Yup.object().shape({
    nama_anggota_keluarga: Yup.string().required(),
    jenis_anggota_keluarga: Yup.string().required(),
    tgl_lahir: Yup.string().required(),
  })

  const formik = useFormik ({
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler
  })

  const ListDataTable = ({index, val, setProcessedData, setModalConfig}) => {
    return (
      <tr key={index}>
        <td className="px-2 text-center" style={tableStyling}>
          {index + 1}
        </td>
        <td style={tableStyling}>
          {val.nama_anggota_keluarga}
        </td>
        <td style={tableStyling}>
          {val.jenis_anggota_keluarga}
        </td>
        <td style={tableStyling}>
          {val.tgl_lahir}
        </td>
        <td style={tableStyling}>
          {val.keterangan}
        </td>
        <td className="d-flex justify-content-center align-items-center btn-group px-1" style={tableStyling}>
          <ActionButton 
            size="sm"
            variant="success"
            text={<IoPencilOutline />}
            onClick={() => setEditedData(val)}
          />
          <ActionButton 
            size="sm"
            variant="danger"
            text={<IoTrashOutline />}
            onClick={() => {
              setProcessedData(val)
              setModalConfig({
                show: true,
                type: 'danger'
              })
            }}
          />
        </td>
      </tr >
    )
  }

  const PageModal = () => {
    const ModalDelete = () => {
      const [isDeleting, setIsDeleting] = useState(false)

      const deleteDataHandler = () => {
        setIsDeleting(true)
        
        RegistrasiKaryawanApi.deleteKeluarga({id_anggota_keluarga: processedData.id_anggota_keluarga})
        .then(() => {
          setModalConfig({
            ...modalConfig,
            show: false
          })
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil dihapus!'
          })

          getDataKeluarga()
        })
        .finally(() => setIsDeleting(false))
      }

      useEffect(() => {
        return () => {
          setIsDeleting(false)
        }
      }, [])

      const ModalDeleteText = () => (
        <>
          <h5>
            <span>Hapus data dengan nama: </span>
            <br/>
            <b>{processedData.nama_anggota_keluarga}</b>
          </h5>
          <small className="text-danger">Data yang dihapus tidak dapat dikembalikan!</small>
        </>
      )

      const ModalDeleteButton = () => (
        <div className="d-flex justify-content-center mt-3">
          <ActionButton 
            variant="outline-secondary"
            className="m-1"
            text="Batal"
            onClick={() => setModalConfig({
              ...modalConfig,
              show: false
            })}
          />
          <ActionButton 
            variant="danger"
            className="m-1"
            text="Hapus Data"
            loading={isDeleting}
            onClick={deleteDataHandler}
          />
        </div>
      )

      return (
        <>
          <Modal.Body className="text-center">
            <ModalDeleteText />
            <ModalDeleteButton />
          </Modal.Body>
        </>
      )
    }

    return (
      <Modal 
        show={modalConfig.show}
        onHide={() => setModalConfig({
          ...modalConfig,
          show: false
        })}
      >
        <Modal.Header closeButton>
          <span className="text-danger">Hapus Data Keluarga</span>
        </Modal.Header>
        <ModalDelete />
      </Modal>
    )
  }

  const EditDataTable = ({index, val}) => {
    const formEditInitialValues = {
      id_anggota_keluarga: val.id_anggota_keluarga,
      nama_anggota_keluarga: val.nama_anggota_keluarga,
      jenis_anggota_keluarga: val.jenis_anggota_keluarga,
      tgl_lahir: val.tgl_lahir,
      keterangan: val.keterangan,
    }
  
    const formEditValidationSchema = Yup.object().shape({
      id_anggota_keluarga: Yup.string().required(),
      nama_anggota_keluarga: Yup.string().required(),
      jenis_anggota_keluarga: Yup.string().required(),
      tgl_lahir: Yup.string().required(),
    })
  
    const formEditSubmitHandler = values => {
      RegistrasiKaryawanApi.updateKeluarga({
        id_karyawan: idKaryawan,
        id_anggota_keluarga: values.id_anggota_keluarga,
        nama_anggota_keluarga: values.nama_anggota_keluarga,
        jenis_anggota_keluarga: values.jenis_anggota_keluarga,
        tgl_lahir: values.tgl_lahir,
        keterangan: values.keterangan,
      })
      .then(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil diubah!'
        })
        setEditedData({})
        getDataKeluarga()
      })
    }
  
    const editFormik = useFormik ({
      enableReinitialize: true,
      initialValues: formEditInitialValues,
      validationSchema: formEditValidationSchema,
      onSubmit: formEditSubmitHandler
    })
  
    const {values, errors, touched, setFieldValue, handleChange, handleSubmit} = editFormik

    return (
      <tr key={index}>
        <td style={tableStyling} className="text-center">{index + 1}</td>
        <td style={tableStyling}>
          <input 
            name="nama_anggota_keluarga"
            className={`form-control form-control-sm ${errors.nama_anggota_keluarga && touched.nama_anggota_keluarga ? 'is-invalid' : ''}`}
            onChange={handleChange}
            value={values.nama_anggota_keluarga}
            readOnly={isFormHeader ? true : false}
          />
        </td>
        <td style={tableStyling} >
          <select
            name="jenis_anggota_keluarga"
            className={`custom-select custom-select-sm ${errors.jenis_anggota_keluarga && touched.jenis_anggota_keluarga ? 'is-invalid' : ''}`}
            onChange={handleChange}
            disabled={isFormHeader ? true : false}
            defaultValue={values.jenis_anggota_keluarga}
          >
            <option value="" hidden>Pilih status keluarga</option>
            <option value="Ayah">Ayah</option>
            <option value="Ibu">Ibu</option>
            <option value="Saudara Kandung">Saudara Kandung</option>
            <option value="Suami">Suami</option>
            <option value="Istri">Istri</option>
            <option value="Anak">Anak</option>
            <option value="Paman">Paman</option>
            <option value="Bibi">Bibi</option>
            <option value="Keponakan">Keponakan</option>
            <option value="Sepupu">Sepupu</option>
            <option value="Kakek">Kakek</option>
            <option value="Nenek">Nenek</option>
          </select>
        </td>
        <td style={tableStyling}>
          <input
            name="tgl_lahir"
            type="date"
            className={`form-control form-control-sm ${errors.tgl_lahir && touched.tgl_lahir ? 'is-invalid' : ''}`}
            onChange={handleChange}
            value={values.tgl_lahir}
            readOnly={isFormHeader ? true : false}
          />
        </td>
        <td style={tableStyling}>
          <input
            name="keterangan"
            className={`form-control form-control-sm ${errors.keterangan && touched.keterangan ? 'is-invalid' : ''}`}
            onChange={handleChange}
            value={values.keterangan}
            readOnly={isFormHeader ? true : false}
          />
        </td>
        <td style={tableStyling}>
          <div className="d-flex justify-content-around align-items-center btn-group px-1">
            <ActionButton 
              size="sm"
              variant="outline-success"
              text={<IoCheckmarkOutline />}
              onClick={handleSubmit}
            />
            <ActionButton 
              size="sm"
              variant="outline-danger"
              text={<IoCloseOutline />}
              onClick={() => setEditedData({})}
            />
          </div>
        </td>
      </tr>
    )
  }
  
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit, isSubmitting} = formik

  return (
    <>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({
          ...alertConfig,
          show: false
        })}
      />
      {loading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : 
        <div className="table-responsive">
          <table className="table table-bordered bg-white table-sm">
            <thead className="text-center">
              <tr>
                <th style={{...tableStyling, width: 40}}>No.</th>
                <th style={tableStyling}>Nama Anggota Keluarga</th>
                <th style={tableStyling}>Status Anggota Keluarga</th>
                <th style={{...tableStyling, width: 70}}>Tgl. Lahir</th>
                <th style={{...tableStyling, minWidth: '100px', width: '150px'}}>Keterangan</th>
                <th style={{...tableStyling, width: '60px'}}>Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center"></td>
                <td style={{...tableStyling, minWidth: '150px', width: '225px'}}>
                  <input 
                    name="nama_anggota_keluarga"
                    className={`form-control form-control-sm ${errors.nama_anggota_keluarga && touched.nama_anggota_keluarga ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                    value={values.nama_anggota_keluarga}
                    readOnly={isFormHeader ? true : false}
                  />
                </td>
                <td style={{...tableStyling, minWidth: '150px', width: '225px'}}>
                  <select
                    name="jenis_anggota_keluarga"
                    className={`custom-select custom-select-sm ${errors.jenis_anggota_keluarga && touched.jenis_anggota_keluarga ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                    disabled={isFormHeader ? true : false}
                  >
                    <option value="" hidden>Pilih status keluarga</option>
                    <option value="Ayah">Ayah</option>
                    <option value="Ibu">Ibu</option>
                    <option value="Saudara Kandung">Saudara Kandung</option>
                    <option value="Suami">Suami</option>
                    <option value="Istri">Istri</option>
                    <option value="Anak">Anak</option>
                    <option value="Paman">Paman</option>
                    <option value="Bibi">Bibi</option>
                    <option value="Keponakan">Keponakan</option>
                    <option value="Sepupu">Sepupu</option>
                    <option value="Kakek">Kakek</option>
                    <option value="Nenek">Nenek</option>
                  </select>
                </td>
                <td style={tableStyling}>
                  <input
                    name="tgl_lahir"
                    type="date"
                    className={`form-control form-control-sm ${errors.tgl_lahir && touched.tgl_lahir ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                    value={values.tgl_lahir}
                    readOnly={isFormHeader ? true : false}
                  />
                </td>
                <td>
                  <input
                    name="keterangan"
                    className={`form-control form-control-sm ${errors.keterangan && touched.keterangan ? 'is-invalid' : ''}`}
                    onChange={handleChange}
                    value={values.keterangan}
                    readOnly={isFormHeader ? true : false}
                  />
                </td>
                <td style={tableStyling}>
                  <div className="d-flex justify-content-center align-items-center btn-group px-1">
                    <ActionButton 
                      size="sm"
                      variant={isFormHeader ? 'info' : 'secondary'}
                      text={<IoTextOutline />}
                      onClick={() => setIsFormHeader(!isFormHeader)}
                    />
                    <ActionButton 
                      size="sm"
                      text={<IoAddOutline />}
                      onClick={handleSubmit}
                      loading={loadingTambah}
                    />
                  </div>
                </td>
              </tr>
              {dataKeluarga.map((val, index) => {
                return editedData === val
                  ? <EditDataTable
                      index={index} 
                      val={val}
                    />
                  : <ListDataTable 
                      index={index}
                      val={val}
                      editedData={editedData}
                      setEditedData={setEditedData}
                      setProcessedData={setProcessedData}
                      setModalConfig={setModalConfig}
                    />
              })}
            </tbody>
          </table>
        </div>        
      }

      <PageModal />
    </>
  )
}

export default FormDataKeluarga
