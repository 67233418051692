// React
import React, { useState, useEffect } from "react";

// Component
import { ButtonGroup, Modal } from "react-bootstrap";
import { TableNumber } from "utilities";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  Alert,
  Input,
  Pagination,
  TextArea,
  Switch,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  SelectSearch,
} from "components";

// API
import { StatusKaryawanApi } from "api";
import axios from "axios";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

const StatusKaryawan = ({ setNavbarTitle }) => {
  // Title
  const title = "Status Pekerja";

  // indikator pemanggilan data sedang dimuat di server
  const [isPageLoading, setIsPageLoading] = useState(true);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState("");

  // menangani modal form tambah
  const [isCreateForm, setIsCreateForm] = useState(false);
  // menangani modal form ubah
  const [isUpdateform, setIsUpdateform] = useState(false);
  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  // data jenis anggaran
  const [data, setData] = useState([]);
  const [dataJenis, setDataJenis] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // menampung data yang akan diubah
  const [updateData, setUpdateData] = useState([]);
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    searchKey: "",
  });

  // request data dari server
  const getData = () => {
    setIsPageLoading(true);
    setIsSearching(false);

    // request data ke server
    axios
      .all([
        StatusKaryawanApi.getPage(page, dataLength, searchKey),
        StatusKaryawanApi.getDropdownJenis(),
      ])
      .then(
        axios.spread((res, jenis) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
          setDataJenis(jenis.data.data);
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      )
      .finally(() => {
        setIsPageLoading(false);
      });
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();

    // set loading
    setIsPageLoading(true);

    // nilai dari form search
    const key = searchKey;

    StatusKaryawanApi.search(key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setAlertConfig({
          variant: "primary",
          searchKey: key,
        });
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Gagal untuk memuat data",
        });
      })
      .finally(() => {
        setPage(1);
        setIsSearching(true);
        setIsPageLoading(false);
        setShowAlert(true);
      });
  };

  // menangani hide/show data
  const changeDataStatus = (status, id) => {
    setIsPageLoading(true);
    setShowAlert(false);

    const value = {
      id_status_pekerja: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        show: true,
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? StatusKaryawanApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : StatusKaryawanApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle(title);

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsPageLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // skema validasi form
  const formValidationSchema = Yup.object().shape({
    id_jenis_pekerja: Yup.string().required("Jenis Pekerja wajib diisi"),
    kode_pekerja: Yup.string()
      .required("Kode Pekerja wajib diisi")
      .test({
        name: 'kode pekerja',
        message: 'Kode pekerja tidak boleh lebih dari 2 karakter',
        test: val => val?.length < 3
      }),
    nama_status_pekerja: Yup.string().required("Nama Status Pekerja wajib diisi"),
  });

  // nilai awal form
  const formInitialValues = {
    id_status_pekerja: updateData.id_status_pekerja,
    id_jenis_pekerja: updateData.id_jenis_pekerja,
    nama_jenis_pekerja: updateData.nama_jenis_pekerja,
    kode_pekerja: updateData.kode_pekerja,
    nama_status_pekerja: updateData.nama_status_pekerja,
    keterangan: updateData.keterangan,
  };

  // modal tambah
  const TambahModal = () => {
    // request tambah data ke server
    const formSubmitHandler = (values) => {
      StatusKaryawanApi.create(values)
        .then((res) => {
          console.log(res);
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Tambah data berhasil!",
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Tambah data gagal!`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsCreateForm(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Jenis Pekerja"
                  name="id_jenis_pekerja"
                  defaultValue={{
                    label: updateData.nama_jenis_pekerja
                      ? updateData.nama_jenis_pekerja
                      : "Pilih Jenis Pekerja",
                    value: updateData.id_jenis_pekerja ? updateData.id_jenis_pekerja : "",
                  }}
                  onChange={(val) => {
                    setFieldValue("id_jenis_pekerja", val.value);
                  }}
                  option={dataJenis.map((val) => {
                    return {
                      value: val.id_jenis_pekerja,
                      label: val.nama_jenis_pekerja,
                    };
                  })}
                  error={errors.id_jenis_pekerja && touched.id_jenis_pekerja && true}
                  errorText={
                    errors.id_jenis_pekerja && touched.id_jenis_pekerja && errors.id_jenis_pekerja
                  }
                />

                <Input
                  label="Kode Pekerja"
                  name="kode_pekerja"
                  value={values.kode_pekerja}
                  error={errors.kode_pekerja && touched.kode_pekerja && true}
                  errorText={errors.kode_pekerja}
                  onChange={handleChange}
                />

                <Input
                  label="Status Pekerja"
                  name="nama_status_pekerja"
                  value={values.nama_status_pekerja}
                  error={errors.nama_status_pekerja && touched.nama_status_pekerja && true}
                  errorText={errors.nama_status_pekerja}
                  onChange={handleChange}
                />

                <TextArea
                  label="Keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // modal ubah
  const UbahModal = () => {
    // request ubah data ke server
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_status_karyawan: updateData.id_status_karyawan,
        ...values,
      };

      StatusKaryawanApi.update(finalValues)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Ubah data berhasil!",
          });
        })
        .catch(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Ubah data gagal!`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsUpdateform(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateform} onHide={() => setIsUpdateform(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <SelectSearch
                  label="Jenis Pekerja"
                  name="id_jenis_pekerja"
                  defaultValue={{
                    label: updateData.nama_jenis_pekerja
                      ? updateData.nama_jenis_pekerja
                      : "Pilih Jenis Pekerja",
                    value: updateData.id_jenis_pekerja ? updateData.id_jenis_pekerja : "",
                  }}
                  onChange={(val) => {
                    setFieldValue("id_jenis_pekerja", val.value);
                  }}
                  option={dataJenis.map((val) => {
                    return {
                      value: val.id_jenis_pekerja,
                      label: val.nama_jenis_pekerja,
                    };
                  })}
                  error={errors.id_jenis_pekerja && touched.id_jenis_pekerja && true}
                  errorText={
                    errors.id_jenis_pekerja && touched.id_jenis_pekerja && errors.id_jenis_pekerja
                  }
                />

                <Input
                  label="Kode Pekerja"
                  name="kode_pekerja"
                  value={values.kode_pekerja}
                  error={errors.kode_pekerja && touched.kode_pekerja && true}
                  errorText={errors.kode_pekerja}
                  onChange={handleChange}
                />

                <Input
                  label="Status Pekerja"
                  name="nama_status_pekerja"
                  value={values.nama_status_pekerja}
                  error={errors.nama_status_pekerja && touched.nama_status_pekerja && true}
                  errorText={errors.nama_status_pekerja}
                  onChange={handleChange}
                />

                <TextArea
                  label="Keterangan"
                  name="keterangan"
                  value={values.keterangan}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah Data"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // modal hapus
  const HapusModal = () => {
    // set id dari data yang ingin dihapus
    const values = { id_status_pekerja: deleteData.id_status_pekerja };

    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // menangani pembersihan saat fetching data
    useEffect(() => {
      return () => {
        setBtnLoading(false);
      };
    }, []);

    // request hapus data ke server
    const deleteDataHandler = () => {
      // set delete button loading
      setBtnLoading(true);

      StatusKaryawanApi.delete(values)
        .then(() => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          // konfigurasi alert
          setAlertConfig({
            show: true,
            variant: "danger",
            text: `Hapus data gagal! (${err})`,
          });
        })
        .finally(() => {
          // menutup modal
          setIsDeleteData(false);
          // menampilkan alert
          setShowAlert(true);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <span>Nama Status Pekerja : {deleteData.nama_status_pekerja}</span>
      </DeleteModal>
    );
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Jenis Pekerja</Th>
            <Th>Status Pekerja</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed textCenter>{TableNumber(page, dataLength, index)}</TdFixed>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <ButtonGroup size="sm" className="mr-1">
                    <UpdateButton
                      onClick={() => {
                        setUpdateData(val);
                        setIsUpdateform(true);
                      }}
                    />
                    <DeleteButton
                      onClick={() => {
                        setDeleteData(val);
                        setIsDeleteData(true);
                      }}
                    />
                  </ButtonGroup>
                  <Switch
                    id={toString(index + 1)}
                    checked={!val.is_hidden}
                    onChange={() => changeDataStatus(val.is_hidden, val.id_status_pekerja)}
                  />
                </div>
              </TdFixed>
              <Td>{val.nama_jenis_pekerja}</Td>
              <Td>{val.nama_status_pekerja}</Td>
              <Td>{val.keterangan}</Td>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={dataCount < dataLength ? dataCount : page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      {/* head */}
      <CRUDLayout.Head>
        {/* search section */}
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                const key = e.target.value
                setSearchKey(key)
                setAlertConfig({
                  show: key ? true : false,
                  variant: 'primary',
                  text: 'Hasil dari pencarian: ' + key
                })
              }}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* button section */}
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              setUpdateData({
                id_status_pekerja: "",
                id_jenis_pekerja: "",
                nama_jenis_pekerja: "",
                kode_pekerja: "",
                nama_status_pekerja: "",
                keterangan: "",
              });
              setIsCreateForm(true);
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* aLert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* table section */}
      {
        // cek apakah data sedang dimuat (loading)
        isPageLoading === true ? (
          // loading
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          // Ada data
          <Table />
        ) : (
          // Tidak ada data
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* Modal */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default StatusKaryawan;
