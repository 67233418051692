import Logo from "assets/image/LogoITDC.png"
import MenuConfig from "./MenuConfig"
import RoutesConfig from "./RoutesConfig"

export default {
  LOGO: Logo,           //Logo
  MODUL: 'HRD Admin',   // Konfigurasi nama modul pada sidebar
  MENU: MenuConfig,     //Konfigurasi menu pada sidebar
  ROUTES: RoutesConfig  //Konfigurasi routing
}
